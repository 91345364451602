#about .main-img {
    width: auto !important;
    border-radius: 2rem;
}

#about .main-img-mobile {
    border-radius: 2rem;
}

.title {
    font-family: 'Libre Caslon Text';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 3rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    color: #231F20;
}

.hero-text {
    font-style: normal;
    font-weight: 400;
    font-size: 4.5rem;
    line-height: 6rem;
    align-items: center;
    text-transform: uppercase;
    color: #07A780;
}

.subtitle {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 1rem;
    line-height: 160%;
    color: #D2B8AB;
    text-transform: uppercase;
    letter-spacing: .4rem;
}

.emphasis {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 200%;
    color: #231F20;
}

.body-content {
    font-family: 'Red Hat Display';
    font-weight: 500;
    font-size: 1rem;
    color: #746760;
    line-height: 200%;
}

.about-socials a {
    color: #231F20;
}

.about-socials a:hover {
    color: #07a780;
}

.partners {
    border-top: 1px solid #231F20;
    border-bottom: 1px solid #231F20;
}

.big-text {
    font-family: 'Libre Caslon Text';
    font-style: normal;
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 4rem;
    letter-spacing: 0.1em;
}

.stat {
    text-align: center;
}

.med-text {
    font-family: 'Libre Caslon Text';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

/* Breakpoint styles */

/* below large */
@media screen and (max-width: 991px) {
    .stat {
        text-align: center;
        margin: 0px !important;
    }
}