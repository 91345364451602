.modal-content {
    background-color: #FFF4EE;
    padding: 1rem;
}

.modal-header, .modal-footer {
    border: none;
}

button.modal-btn {
    background-color: #231F20;
    border-radius: .5rem;
    border: none;
    color: #fff;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    padding: .75rem 1rem;
    transition: all .3s ease-out;
}

button.modal-btn:hover {
    background-color: #07a780;
    transition: all .15s ease;
}

.modal-body::-webkit-scrollbar {
    width: .75rem;
    position: absolute;
    background-color: #FFF4EE;
  }
  
.modal-body::-webkit-scrollbar-thumb {
background-color: #F2DFD5;
border-radius: .5rem;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 70vw;
    }
}