.navbar {
    background-color: rgba(242, 223, 213, .98)
}

.App-logo {
    width: 65vw;
    max-width: 18rem;
    transition: all .5s ease-out;
}

.App-logo:hover {
    color: #231F20;
    transition: all .5s ease-in;
}

.navbar a {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .15rem;
    color: #746760;
}

.navbar a:hover {
    color: #07a780;
}

a.nav-btn {
    font-family: 'Red Hat Display';
    font-weight: 500;
    background-color: #746760;
    color: #fff;
    border-radius: .5rem;
    text-transform: uppercase;
    letter-spacing: .15rem;
    border: none;
    transition: all .2s ease-out;
    text-decoration: none;
}

a.nav-btn:hover {
    background-color: #07a780;
    color: #fff;
    border: none;
    transition: all .15s ease;
}

a.nav-link.active {
    color: #07a780 !important;
}
