.footer {
    background-color: #07a780;
    color: #F2DFD5;
    border-top: 2rem solid #E9CFC2;
}

.footer-logo {
    width: 3rem;
    color: #F2DFD5;
}

.tag-line {
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: .15rem;
}

.socials a {
    margin-left: 1rem;
    margin-right: 1rem;
    color: #F2DFD5;
}

.socials a:hover {
    color: #fff;
}

.copyrights {
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: .08rem;
}