.news {
    padding-top: 10rem;
}

#newsletter {
    position: relative;
    background-color: #958075;
    color: #fff;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.news-title {
    font-family: 'Libre Caslon Text';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.5rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.dark-section .emphasis {
    color: #fff;
    letter-spacing: .1rem;
}

.dark-section .body-content {
    color: #fff;
    letter-spacing: .1rem;
    line-height: 1.5rem;
    font-weight: 400;
}

.dark-section .btn {
    background: #231F20;
    border-radius: .5rem;
    border: none;
    color: #fff;
    height: 4rem;
}

.dark-section .btn:hover {
    background: #07a780;
}

.dark-section label {
    color: #fff;
    font-weight: 400;
    letter-spacing: .1rem;
}

.dark-section #emailHelp {
    color: #f2f2f2;
    font-weight: 400;
    letter-spacing: .05rem;
}

.dark-section input {
    background-color: #84736B !important;
    border: #D2B8AB 1px solid !important;
}

.dark-section input:hover {
    background-color: #746760 !important;
    border: #D2B8AB 1px solid !important;
}

.dark-section input:focus {
    background-color: #746760 !important;
    border: #f2f2f2 1px solid !important;
    color: #fff;
    letter-spacing: .1rem;
}

#newsletter .container {
    position: relative;
    z-index: 10;
}

#newsletter #oval-leaf {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
}

#newsletter #leaves {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
}