body {
  margin: 0;
  font-family: 'Libre Caslon Text', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2dfd5;
}

body::-webkit-scrollbar {
  width: .75rem;
  position: absolute;
  background-color: #E9CFC2;
}

body::-webkit-scrollbar-thumb {
  background-color: #D2B8AB;
  border-radius: .5rem;
}

.section {
  padding-top: 10rem !important;
}

.section-title {
  font-family: 'Libre Caslon Display';
  font-style: normal;
  font-weight: 400;
  font-size: 10rem;
  line-height: 12rem;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #D2B8AB;
  opacity: .5;
}

@media screen and (max-width: 991.8px) {
  .section-title {
    font-size: 7rem;
  }
}

/* styles for smaller screens */
@media screen and (max-width: 767.98px) {
  .section-title {
    font-size: 3rem;
    margin: 0px;
  }

  .hero-text {
    font-size: 3rem !important;
  }

  body::-webkit-scrollbar {
    width: 0rem;
  }
}

input:focus, textarea:focus, select:focus {
  outline-style: none !important;
  box-shadow: none !important;
}

iframe {
  border-radius: 1rem;
}

@media screen and (max-width: 768px) {
  .section {
    padding-top: 1rem !important;
  }
  
}