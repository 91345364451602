.contact-title {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 53px;
    text-transform: uppercase;
    color: #231F20;
}

form {
    font-family: 'Red Hat Display';
    font-weight: 500;
    color: #746760;
}

.section.end {
    padding-bottom: 10rem;
}

#contact input[type='text'], input[type='email'], input[type='checkbox'], textarea[type='text'] {
    background-color: #FFF0E8;
    border: #B59C8F 1px solid;
}

#contact input[type='text']:hover, input[type='email']:hover, input[type='checkbox']:hover, textarea[type='text']:hover {
    background-color: #FFF4EE;
    border: #746760 1px solid;
    color: #231F20;
}

#contact input[type='text']:focus, input[type='email']:focus, input[type='checkbox']:focus, textarea[type='text']:focus {
    background-color: #FFF4EE;
    border: #231F20 1px solid;
    letter-spacing: .1rem;
}

#contact input:checked {
    background-color: #07a780 !important;
    border-color: #07a780;
}

#contact .form-check-input:hover {
    cursor: pointer;
}

#contact .form-check-label:hover {
    color: #231F20;
    cursor: pointer;
}

#contact select {
    background-color: #FFF0E8;
    border: #B59C8F 1px solid;
    letter-spacing: .05rem;
}

#contact select:hover {
    background-color: #FFF4EE;
    border: #746760 1px solid;
    color: #231F20;
    cursor: pointer;
}

#contact select:focus {
    background-color: #FFF4EE;
    border: #231F20 1px solid;
}