#home {
    padding-top: 4rem;
}

.main-img {
    width: 30vw;
    border-radius: 12rem;
    max-width: 328px;
    max-height: 695px;
}

.main-img-mobile {
    width: 100% !important;
    border-radius: 50vw;
}

.title {
    font-family: 'Libre Caslon Text';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 3rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    color: #231F20;
}

.rotating-words {
    font-style: normal;
    font-weight: 400;
    font-size: 4.5rem;
    line-height: 6rem;
    align-items: center;
    text-transform: uppercase;
    color: #07A780;
}

.subtitle {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 1rem;
    line-height: 160%;
    color: #D2B8AB;
    text-transform: uppercase;
    letter-spacing: .4rem;
}

.body-content {
    font-family: 'Red Hat Display';
    font-weight: 500;
    font-size: 1rem;
    color: #231f20;
    line-height: 200%;
}

.btn {
    border: 2px solid #746760;
    border-radius: 4px;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #746760;
}

.btn:hover {
    border: 2px solid #231F20;
    border-radius: 4px;
    background-color: #231F20;
    font-weight: 500;
    color: #fff;
}

.partners {
    border-top: 1px solid #231F20;
    border-bottom: 1px solid #231F20;
    margin-top: 3rem !important;
}

.main-img-mobile {
    width: 100%;
}


/* Breakpoint styles */

/* below large */
@media screen and (max-width: 991px) {
    .main-img {
        display: none;
    }

    .subtitle {
      font-size: 1rem !important;
      letter-spacing: 0.2rem !important;
    }

    .rotating-words {
        font-size: 4rem;
    }
}

/* above large */
@media screen and (min-width: 991.1px) {
    .main-img-mobile {
        display: none;
    }
}