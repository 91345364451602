.work-title {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 53px;
    text-transform: uppercase;
}

.work-subtitle {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #746760;
    fill: #746760;
}

#works a {
    height: 1rem;
    font-size: 1rem;
    font-weight: 600;
    color: #231F20;
    transition: all .3s ease-out;
}

#works a:hover {
    color: #07a780;
    transition: all .1s ease-in;
}

/* override slick styles */
.slick-dots {
    position: static;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.slick-dots li button::before {
    color: #D2B8AB;
    opacity: 1;
}

.slick-dots li.slick-active button::before {
    font-size: .75rem;
    color: #231F20;
    opacity: 1;
}

#works .slider-btn {
    background: none;
    border: none;
    font-family: 'Libre Caslon Text';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    padding: 0;
    color: #746760;
    transition: all .3s ease-out;
}

#works .slider-btn:hover {
    color: #07a780;
    transition: all .1s ease-in;
}

#works img {
    max-height: 300px;
}